<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Company Calender"
      slot="title"
      link="/helpContent/CompanyCalender"
    />
    <div class="mainCom" :style="minHeight == null ? '' : `height:${minHeight}px`">
      <div class="blockDefinations">
          <div class="today block">
              <span class="calenderText">Today</span>
          </div>
          <div class="holiday block">
              <span class="calenderText">Holidays</span>
          </div>
          <div class="leave block">
              <span class="calenderText">Leaves</span>
          </div>
          <div class="birthday block">
              <span class="calenderText">Birthdays</span>
          </div>
          <div class="event block">
              <span class="calenderText">Events</span>
          </div>
          <div class="celebration block">
              <span class="calenderText">Celebration</span>
          </div>
          <div class="weekoff block">
              <span class="calenderText">WeekOff</span>
          </div>
      </div>
      <vue-cal
        :time="true"
        active-view="month"
        :disable-views="['years', 'year']"
        events-on-month-view="short"
        :events="events"
        style="height: 600px"
        :time-from="9 * 60"
        :time-to="19 * 60"
        :time-step="30"
      >
      </vue-cal>
    </div>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import loadingMixin from "Mixins/loadingMixin";
import moment from "moment";
import { calenderList } from "../api";
export default {
  name: "CompanyCalendar",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    VueCal,
  },
  props: {
    companyCalendar: {
      type: Array,
      default: () => [],
    },
    minHeight: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      events: []
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  created() {
    this.getHolidayList();
  },
  methods: {
    getHolidayList() {
      const data = {
        year: moment().format("YYYY"),
        month: moment().format("MM"),
      };
      calenderList(data).then((res) => {
        this.events = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
    .mainCom {
        height: 100%;
    }

    .vuecal__event {
        text-align: left;
    }

    .vuecal__event-title {
        font-size: 1.1em;
        font-weight: bold;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    }

    .vuecal__event-time{
        font-size: 0.7em;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    }

    .vuecal__cell--today{
        background-color: #fff88a5e !important;
        z-index: auto;
    }

    /* Different color for different event types. */
    .vuecal__event.Holiday {
        background-color: #68bbae;
        color: #fff;
    }

    .vuecal__event.Leave {
        background-color: #b3cdfa;
        color: #fff;
    }

    .vuecal__event.Birthday {
        background-color: #f6a4d1;
        color: #fff;
    }

    .vuecal__event.Event {
        background-color: #ac96eb;
        color: #fff;
    }

    .vuecal__event.Celebration {
        background-color: #ffd4ac;
        color: #fff;
    }

    .vuecal__event.WeekOff {
        background:
            #fff7f0
            repeating-linear-gradient(
                -45deg,
                rgba(255, 162, 87, 0.25),
                rgba(255, 162, 87, 0.25) 5px,
                rgba(255, 255, 255, 0) 5px,
                rgba(255, 255, 255, 0) 15px
            );
        color: orangered;
    }

    .vuecal--no-time .vuecal__event {
        min-height: 8px;
        margin-top: 3px !important;
        margin-bottom: 3px !important;
        padding: 5px;
    }

    .blockDefinations{
        display: flex;
        justify-content:space-evenly;
        padding: 20px 0px 20px 0px;
    }

    .calenderText{
        margin-left: 20px;
    }

    .block{
        width: 15px;
        height: 15px;
    }

    .today{
        background-color: #fff88a5e;
    }
    
    .holiday{
        background-color: #68bbae;
    }

    .leave{
        background-color: #b3cdfa;
    }

    .birthday{
        background-color: #f6a4d1;
    }

    .event{
        background-color: #ac96eb;
    }

    .celebration{
        background-color: #ffd4ac;
    }

    .weekoff{
        background:
            #fff7f0
            repeating-linear-gradient(
                -45deg,
                rgba(255, 162, 87, 0.25),
                rgba(255, 162, 87, 0.25) 5px,
                rgba(255, 255, 255, 0) 5px,
                rgba(255, 255, 255, 0) 15px
            );
    }
</style>
